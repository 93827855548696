import * as React from 'react';
import classnames from 'classnames';

import { AppLink } from '@src/services/app/components/AppLink/AppLink';
import { slugify } from '@src/utils/strings';
import { Data, data } from './data';

import styles from './index.module.css';

export interface HomepageBrandsProps {
  className?: string;
}

/**
 * @name HomepageBrands
 * @description Responsive layout of some brands we carry and just so
 * happen to really like using.
 */
export const HomepageBrands = (props: HomepageBrandsProps) => {
  const { className } = props;

  // Styles
  const cssComponent = classnames(styles.component, className);

  // Markup
  const renderData = (entry: Data, index: number) => {
    const { image, title, width } = entry;
    const id = slugify(title);
    const url = `/brands#${id}`;

    return (
      <AppLink key={index} href={url} className="mx-1x shrink-0">
        <img
          alt={title}
          className={styles.brand}
          key={index}
          src={image}
          width={width}
        />
      </AppLink>
    );
  };

  return (
    <>
      <div className={cssComponent}>
        <div className="ui-container-xl">
          <h2 className={styles.heading}>A few of our go to brands</h2>
        </div>
      </div>
      <div className={styles.content}>
        {data.sort((a, b) => a.title.localeCompare(b.title)).map(renderData)}
      </div>
    </>
  );
};
